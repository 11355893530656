import Instruction from "./components/Instruction";
import Main from "./components/Main";
import './style.css'
function App() {
  return (
    <>
      <Instruction />
      <Main />
    </>
  );
}

export default App;
